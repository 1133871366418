const isValidUrl = (string) => {
  try {
    new URL(string)
    return true
  } catch (err) {
    return false
  }
}

const validateGuideshopJSON = (json) => {
  const errors = []
  if (!json || typeof json !== "object") {
    errors.push("JSON is not an object")
    return {
      isValid: false,
      errors,
    }
  }
  if (json.size === 0) {
    errors.push("JSON is empty")
    return {
      isValid: false,
      errors,
    }
  }
  for (const [key, stateObject] of json.entries()) {
    if (!stateObject || typeof stateObject !== "object") {
      errors.push(`State '${key}' is not an object`)
      continue
    }
    if (typeof stateObject.get("name") !== "string") {
      errors.push(
        `State '${key}' must have a string 'name', is typeof '${typeof stateObject.get("name")}'`
      )
    }
    if (typeof stateObject.get("guideshops") !== "object") {
      errors.push(
        `State '${key}' must have a 'guideshops' object is typeof '${typeof stateObject.get("guideshops")}'`
      )
      continue
    }
    if (stateObject.get("guideshops").size === 0) {
      errors.push(`State '${key}' guideshops object is empty`)
      continue
    }
    const guideshops = stateObject.get("guideshops")
    for (const [guideshopKey, guideshop] of guideshops.entries()) {
      if (typeof guideshop.get("name") !== "string") {
        errors.push(
          `Guideshop '${guideshopKey}'  must have a string 'name', is typeof '${typeof guideshop.get("name")}'`
        )
      }
      if (typeof guideshop.get("scheduling_url") !== "string") {
        errors.push(
          `Guideshop '${guideshopKey}'  must have a string 'scheduling_url', is typeof '${typeof guideshop.get("scheduling_url")}'`
        )
      } else if (!isValidUrl(guideshop.get("scheduling_url"))) {
        errors.push(
          `Guideshop '${guideshopKey}' must have a valid url, is '${guideshop.get("scheduling_url")}'`
        )
      }
    }
  }
  return {
    isValid: errors.length === 0,
    errors,
  }
}

export { validateGuideshopJSON }

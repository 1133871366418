import { post, put, del, get } from "highline/api/v2_client"
import { get as getCookie } from "highline/utils/cookies"
import recaptchaGuard from "highline/utils/recaptcha_guard"

export const createAccount = (userAndOrderInfo) => {
  return post("/users", {
    ...userAndOrderInfo,
    constructorio_cookie: getCookie("ConstructorioID_client_id"),
  })
}

export const login = recaptchaGuard("createUserSession", (loginAndOrderInfo, recaptchaToken) =>
  post(
    "/sessions",
    {
      ...loginAndOrderInfo,
      constructorio_cookie: getCookie("ConstructorioID_client_id"),
    },
    {
      "X-Recaptcha-Token": recaptchaToken,
    }
  )
)

export const recoverPassword = (email) => {
  return post("/password_recovery", { email })
}

export const resetPassword = (password, reset_password_token) => {
  return put("/password_recovery", { password, reset_password_token })
}

export function logout(authenticationToken) {
  return del("/sessions", { authentication_token: authenticationToken })
}

export const checkEmail = (encryptedValue) => {
  return get("/ccpa/email_exists", { encryptedValue })
}
export const checkOptanonStatus = (emailId) => {
  return post("/ccpa/cookie_exists", { emailId })
}

import React, { useState, useEffect } from "react"
import Select from "react-select"
import ImmutablePropTypes from "react-immutable-proptypes"
import PropTypes from "prop-types"
import { Map } from "immutable"

import styles from "highline/styles/components/contentful/contentful_guideshop_selector.module.css"
import Button from "highline/components/button"
import Modal from "highline/components/modal"
import { validateGuideshopJSON } from "./contentful_guideshop_validator"
import Rollbar from "highline/utils/rollbar"
import { trackEvent } from "highline/utils/segment/segment_helper"

const ContentfulGuideShopSelector = ({
  stateDropdownLabel,
  guideshopDropdownLabel,
  callToActionButtonText,
  guideshopDropdownErrorMessage,
  stateDropdownErrorMessage,
  locations,
}) => {
  const [statesNames, setStatesNames] = useState([])
  const [isJSONValid, setIsJSONValid] = useState(false)
  const [guideShopNames, setGuideShopNames] = useState([])
  const [selectedState, setSelectedState] = useState(null)
  const [selectedGuideshop, setSelectedGuideshop] = useState(null)
  const [stateError, setStateError] = useState(false)
  const [guideshopError, setGuideshopError] = useState(false)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const stateNames = []
    const jsonValidation = validateGuideshopJSON(locations)
    if (jsonValidation.isValid) {
      locations.forEach((value, key) => {
        stateNames.push({
          value: key,
          label: value.get("name"),
        })
      })
      setIsJSONValid(true)
    } else {
      console.error(
        "Invalid Contentful JSON field type in ContentfulGuideShopSelector content model",
        { errorList: jsonValidation.errors }
      )
      Rollbar.error(
        "Invalid Contentful JSON field type in ContentfulGuideShopSelector content model",
        { errorList: jsonValidation.errors }
      )
    }

    setStatesNames(stateNames.sort((a, b) => a.label.localeCompare(b.label)))
  }, [])

  const resetErrors = () => {
    setStateError(false)
    setGuideshopError(false)
  }

  const handleOnChangeState = (state) => {
    const guideshopsNames = []
    locations.getIn([state.value, "guideshops"]).forEach((value, key) => {
      guideshopsNames.push({
        value: key,
        label: value.get("name"),
      })
    })
    setSelectedState(state)
    setSelectedGuideshop(null)
    setGuideShopNames(guideshopsNames.sort((a, b) => a.label.localeCompare(b.label)))
    resetErrors()
    trackEvent("Book An Appointment - Location Selection", {
      location: state,
    })
  }

  const handleOnChangeGuideshop = (guideshop) => {
    setSelectedGuideshop(guideshop)
    resetErrors()
    trackEvent("Book An Appointment - Guideshop Selection", {
      guideshop,
    })
  }

  const onBookAppointmentButtonClick = () => {
    if (selectedState === null) {
      setStateError(true)
    } else if (selectedState && selectedGuideshop === null) {
      setGuideshopError(true)
    } else {
      trackEvent("Book An Appointment - Make Appointment", {
        location: selectedState,
        guideShop: selectedGuideshop,
      })
      setShowModal(true)
    }
  }

  const handleModalClose = () => {
    setShowModal(false)
  }

  if (!isJSONValid) return null
  return (
    <div className={styles.container}>
      <div className={styles.selectors}>
        <div>
          <label className={styles.label}>{stateDropdownLabel}</label>
          <Select
            options={statesNames}
            aria-label={stateDropdownLabel}
            onChange={handleOnChangeState}
            isSearchable
          />
          {stateError && <p className={styles.error}>{stateDropdownErrorMessage}</p>}
        </div>
        <div>
          <label className={styles.label}>{guideshopDropdownLabel}</label>
          <Select
            options={guideShopNames}
            aria-label={guideshopDropdownLabel}
            value={selectedGuideshop}
            onChange={handleOnChangeGuideshop}
            placeholder={selectedState === null ? "Select a state" : "Select..."}
            isDisabled={selectedState === null}
            isSearchable
          />
          {guideshopError && <p className={styles.error}>{guideshopDropdownErrorMessage}</p>}
        </div>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          align="inline"
          ariaLabel={callToActionButtonText}
          size="small"
          onClick={onBookAppointmentButtonClick}
        >
          {callToActionButtonText}
        </Button>
      </div>
      {showModal && (
        <Modal onRequestClose={handleModalClose} padding="spacingXs" layout="roundedSmall">
          <iframe
            title={`${selectedGuideshop.label} Guideshop booking form`}
            className={styles.iframe}
            src={locations.getIn([
              selectedState.value,
              "guideshops",
              selectedGuideshop.value,
              "scheduling_url",
            ])}
          />
        </Modal>
      )}
    </div>
  )
}

ContentfulGuideShopSelector.propTypes = {
  stateDropdownLabel: PropTypes.string.isRequired,
  guideshopDropdownLabel: PropTypes.string.isRequired,
  callToActionButtonText: PropTypes.string.isRequired,
  guideshopDropdownErrorMessage: PropTypes.string.isRequired,
  stateDropdownErrorMessage: PropTypes.string.isRequired,
  locations: ImmutablePropTypes.map.isRequired,
}

ContentfulGuideShopSelector.defaultProps = {
  stateDropdownLabel: "",
  guideshopDropdownLabel: "",
  callToActionButtonText: "",
  guideshopDropdownErrorMessage: "",
  stateDropdownErrorMessage: "",
  locations: Map(),
}

export default ContentfulGuideShopSelector
